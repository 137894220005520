import { Typography } from "@mui/material";

const Footer = () => {

    let year = (new Date()).getFullYear()
    return (
        <div className="footer">
            <Typography style={{ textAlign: 'center', fontSize: '16px' }}>
                Copyright © {year} platinumtherapylights | All Rights Reserved | <a rel="noreferrer" style={{textDecoration: 'none', color: 'black'}} target="_blank" href="https://platinumtherapylights.com/pages/privacy-policy">Privacy Policy</a>
            </Typography>
        </div>
    );
}

export default Footer;