const ViralSweepTrackingScript = () => {
    const scriptId = 'viralsweep';
  
    // Check if script already exists to avoid duplicates
    if (document.getElementById(scriptId)) {
      return null;
    }
  
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = 'https://app.viralsweep.com/assets/js/action.js';
    document.head.appendChild(script);
  
    return null;
  };
  
  export default ViralSweepTrackingScript;