const ViralsweepDownloadPage = () => {
    const scriptId = 'viralsweepDownload';
  
    // Check if script already exists to avoid duplicates
    if (document.getElementById(scriptId))
      return null;

    const script1 = document.createElement("script")
    script1.id = scriptId;
    script1.innerHTML = `function vs_get_cookie(cname){var name = cname + "=";var ca = document.cookie.split(';');for(var i=0; i<ca.length; i++){var c = ca[i].trim();if (c.indexOf(name)==0) return c.substring(name.length,c.length);}return "";} var vs_eidhash =  vs_get_cookie('vseid');`
    document.head.appendChild(script1);
    const script2 = document.createElement("script")
    script2.id = scriptId + "2"
    script2.innerHTML = `vs_tag_url = 'https://app.viralsweep.com/promo/ca/156476-31355_1712171581?h='+vs_eidhash;  var head = document.getElementsByTagName('head')[0]; var script = document.createElement('script'); script.type = 'text/javascript'; script.onload = function() {}; script.src = vs_tag_url; head.appendChild(script);`
    document.head.appendChild(script2)

    return null;
}
 
export default ViralsweepDownloadPage;